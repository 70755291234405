import {useAppSelector} from 'store/customer';
import {selectQFPs} from 'components/customer/QFPRedux/store/qfpSlice';
import {shallowEqual} from 'react-redux';
import {useMemo} from 'react';

export const useUnsavedQFPs = () => {
    const qfps = useAppSelector(selectQFPs, shallowEqual);

    const {hasUnsavedQFPs, unsavedIndexes} = useMemo(() => {
        const unsavedQFPs = qfps
            .map((qfp, index) => ({
                ...qfp,
                index,
            }))
            .filter((qfp) => {
                return !qfp.persisted && !qfp.deleted;
            });

        return {
            hasUnsavedQFPs: unsavedQFPs.length > 0,
            unsavedIndexes: unsavedQFPs.map((qfp) => qfp.index),
        };
    }, [qfps]);

    return {hasUnsavedQFPs, unsavedIndexes};
};
