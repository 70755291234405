import {useCallback} from 'react';
import {useUnsavedQFPs} from 'components/customer/QFPRedux/helpers/useUnsavedQFPs';
import {ConfirmationOption} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {Event} from 'shared/events/Event';
import {useAppDispatch} from 'store/customer';
import {deleteQfp} from 'components/customer/QFPRedux/store/qfpSlice';
import {useQFPSave} from 'components/customer/QFPRedux/helpers/useQFPSave';
import {matchPath, useLocation} from 'react-router-dom';

export const useQFPproductPrompt = (
    showDialog: (options: ConfirmationOption) => void,
    hideDialog: () => void
): Event => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const {hasUnsavedQFPs, unsavedIndexes} = useUnsavedQFPs();
    const {saveProducts, savedDialog} = useQFPSave(showDialog, hideDialog);

    const discardUnsaved = useCallback(() => {
        unsavedIndexes.forEach((index) => {
            dispatch(deleteQfp(index));
        });
    }, [unsavedIndexes]);

    const action = useCallback((): Promise<boolean | void> | boolean => {
        const isQFPPage = matchPath(
            '/v2/job/:jobId/room/:roomId/quick-flat-product',
            location.pathname
        );

        if (isQFPPage && hasUnsavedQFPs) {
            return new Promise((resolve) => {
                showDialog({
                    title: 'Changes not yet saved',
                    message:
                        'There are unsaved products. Do you want to save or discard them before proceeding to submit your job?',
                    hideYesButton: true,
                    hideNoButton: true,
                    buttons: [
                        {
                            name: 'Cancel',
                            controlledHideDialog: true,
                            show: true,
                            variant: 'warning',
                            alignLeft: true,
                            action: () => {
                                hideDialog();
                                resolve(false);
                            },
                        },
                        {
                            name: 'Discard',
                            controlledHideDialog: true,
                            show: true,
                            action: () => {
                                discardUnsaved();
                                resolve(true);
                            },
                        },
                        {
                            name: 'Save',
                            controlledHideDialog: true,
                            show: true,
                            variant: 'primary-color',
                            action: async () => {
                                const save = await saveProducts();

                                if (save) {
                                    const allSaved = await savedDialog(false);

                                    resolve(allSaved);
                                } else {
                                    resolve(save);
                                }
                            },
                        },
                    ],
                });
            });
        } else {
            return true;
        }
    }, [hasUnsavedQFPs, discardUnsaved, location]);

    return {
        action,
    };
};
