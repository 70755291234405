import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useConfirmationDialog} from 'shared';
import {CBCButton} from 'shared/helpers';
import styled from 'styled-components';

const CancelButton = styled(CBCButton)`
    width: 100%;
`;

export const CancelBenchtop = () => {
    const navigate = useNavigate();
    const {jobId} = useParams();
    const {dialog, showDialog} = useConfirmationDialog();

    const handleClick = () => {
        showDialog({
            title: 'Changes not yet saved',
            message:
                'Are you sure you want to leave? Some of your changes have not been saved yet. If you proceed, these changes will be lost.',
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    show: true,
                    name: 'Proceed',
                    variant: 'danger',
                    controlledHideDialog: true,
                    action: () => {
                        navigate(`/v2/job/${jobId}/dashboard`);
                    },
                },
                {
                    show: true,
                    name: 'Cancel',
                },
            ],
        });
    };

    return (
        <>
            {dialog}
            <CancelButton
                onClick={handleClick}
                iconName="Button-Cancel.svg"
                className="job-button button-light"
                style={{color: '#8A8080'}}>
                Cancel
            </CancelButton>
        </>
    );
};
