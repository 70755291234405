import React, {useCallback} from 'react';
import {useJobContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {ConfirmationOption} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {deleteRoom} from 'service';
import {useAppDispatch} from 'store/customer';
import {invalidateJob} from 'components/customer/Job/store/jobApi';
import {Event} from 'shared/events/Event';

const emptyRoomMessage = `It looks like one or more rooms in this job don't contain any products. To submit the job, these empty rooms must be removed. Would you like us to go ahead and delete them for you?`;

export const useHandleEmptyRooms = (
    showDialog: (options: ConfirmationOption) => void,
    hideDialog: () => void
): Event => {
    const dispatch = useAppDispatch();
    const {job} = useJobContext() as PartialJob;

    const action = useCallback((): Promise<boolean | void> | boolean => {
        const hasEmptyRoom = (job.rooms ?? []).some(
            ({productCount}: {productCount: number}) => productCount === 0
        );
        if (hasEmptyRoom) {
            return new Promise((resolve) => {
                showDialog({
                    title: 'Delete empty rooms?',
                    message: (
                        <div style={{textAlign: 'center'}}>
                            {emptyRoomMessage}
                        </div>
                    ),
                    hideYesButton: true,
                    hideNoButton: true,
                    buttons: [
                        {
                            show: true,
                            name: 'Yes',
                            controlledHideDialog: true,
                            variant: 'danger',
                            action: () => {
                                const roomsForDeletion = (
                                    job.rooms ?? []
                                ).filter((room) => room.productCount === 0);

                                // NOTE: this can probably be a single api request
                                // which deletes all empty rooms.
                                Promise.all(
                                    roomsForDeletion.map((room) =>
                                        deleteRoom(job.id, room.id)
                                    )
                                )
                                    .then(() => {
                                        // dispatch(invalidateJob());
                                        resolve(true);
                                    })
                                    .catch(() => {
                                        resolve(false);
                                        hideDialog();
                                    })
                                    .finally(() => {
                                        dispatch(invalidateJob());
                                    });
                            },
                        },
                        {
                            show: true,
                            name: 'No',
                            controlledHideDialog: true,
                            action: () => {
                                resolve(false);
                                hideDialog();
                            },
                        },
                    ],
                });
            });
        } else {
            return true;
        }
    }, [job]);

    return {
        action,
    };
};
