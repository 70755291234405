import {cloneDeep} from 'lodash';
import React, {useCallback, useEffect, useMemo} from 'react';
import {store} from 'store/customer/storeSetup';
import {
    triggerSave,
    selectQFPs,
    clearDeleted,
    Status,
    selectTotalSaving,
    totalSavingSet,
    selectStatus,
    statusSet,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {useFetchStructure} from 'components/customer/QFPRedux/helpers/useFetchStructure';
import {validateProducts} from 'shared';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {ConfirmationOption} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {Alert, ListGroup} from 'react-bootstrap';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {ProgressBar} from 'components/customer/QFPRedux/ProgressBar';
import {useNavigate, useParams} from 'react-router-dom';

export const useQFPSave = (
    showDialog: (options: ConfirmationOption) => void,
    hideDialog: () => void
) => {
    const navigate = useNavigate();

    const {jobId} = useParams();

    const dispatch = useAppDispatch();
    const fetchStructure = useFetchStructure();
    const {data: userProfile} = useGetUserQuery();

    const status = useAppSelector(selectStatus);
    const totalSaving = useAppSelector(selectTotalSaving);
    const qfps = useAppSelector(selectQFPs, shallowEqual);

    const allQFPsProcessed = useMemo(() => {
        if (totalSaving == 0) return true;

        const qfpProcessed = qfps.filter(
            (qfp) => !qfp?.deleted && (qfp.persisted || qfp.error)
        ).length;

        return qfpProcessed == totalSaving;
    }, [qfps, totalSaving]);

    const navigateToDashboard = useCallback(
        (productId?: number) => {
            let query = '';
            if (typeof productId !== 'undefined') query = `?pid=${productId}`;

            navigate(`/v2/job/${jobId}/dashboard${query}`);
        },
        [navigate, jobId]
    );

    const saveProducts = useCallback(async () => {
        const qfps = selectQFPs(store.getState());
        const saveList = cloneDeep(
            qfps
                .map((qfp, index) => ({...qfp, index}))
                .filter((qfp) => !qfp?.deleted)
        );

        if (saveList.length > 0) {
            const invalid = [];
            // Getting validation data and validating each products
            for (const qfp of saveList) {
                const validStructure = await fetchStructure(qfp.product.type);
                const product = qfp.product;

                const heightField = validStructure.heightField;
                const widthField = validStructure.widthField;

                const errors = validateProducts(
                    {
                        ...product,
                        [heightField]: product.height,
                        [widthField]: product.width,
                        hor_grain_ext: product.hor_grain,
                    },
                    validStructure.validation,
                    {
                        ...qfp.validationData,
                        ...(qfp.validationData?.cabinet_ext_colour?.hasOwnProperty(
                            'horGrain'
                        ) && {
                            cabinet_ext_colour: {
                                ...qfp.validationData.cabinet_ext_colour,
                                isGrained:
                                    qfp.validationData.cabinet_ext_colour
                                        .horGrain,
                            },
                        }),
                    },
                    true
                );

                if (errors.length > 0) invalid.push(qfp);
            }

            // Stops saving/deleting progress if there are any invalid products
            // and displays a dialog
            if (invalid.length > 0) {
                showDialog({
                    title: userProfile.qFPMenuText
                        ? userProfile.qFPMenuText
                        : 'Quick Products',
                    message: (
                        <>
                            <Alert
                                variant="danger"
                                style={{marginTop: 0, marginBottom: '15px'}}>
                                <strong>
                                    Please validate these products to save
                                </strong>
                            </Alert>
                            <ListGroup
                                style={{
                                    maxHeight: '300px',
                                    overflowY: 'scroll',
                                }}>
                                {invalid.map((qfp) => (
                                    <ListGroup.Item key={qfp.index}>
                                        Errors on Product{' '}
                                        {qfp.product.room_cab_number}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </>
                    ),
                    hideYesButton: true,
                    hideNoButton: true,
                    buttons: [
                        {
                            show: true,
                            name: 'Ok',
                        },
                    ],
                });

                return false;
            }

            // Trigger save for all the products
            dispatch(totalSavingSet(saveList.length));
            dispatch(statusSet(Status.SAVING));
        } else {
            dispatch(statusSet(Status.DONE));
        }
        dispatch(triggerSave(true));
        return true;
    }, []);

    const savedDialog = useCallback(
        (showConfirmation = true) => {
            return new Promise<boolean>((resolve) => {
                showDialog({
                    title: userProfile.qFPMenuText
                        ? userProfile.qFPMenuText
                        : 'Quick Products',
                    message: (
                        <ProgressBar
                            showConfirmation={showConfirmation}
                            hideDialog={hideDialog}
                            resolve={resolve}
                        />
                    ),
                    hideYesButton: true,
                    hideNoButton: true,
                    hideFooter: true,
                });
            });
        },
        [status, navigateToDashboard]
    );

    useEffect(() => {
        if (status == Status.SAVING && allQFPsProcessed) {
            // point of no return, cannot undo after this.
            dispatch(clearDeleted());
            setTimeout(() => {
                dispatch(statusSet(Status.DONE));
            }, 500);
        }
    }, [status, allQFPsProcessed]);

    return {
        status,
        saveProducts,
        savedDialog,
    };
};
